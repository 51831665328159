import { PageHead, Card, HomeIconIcon, IconButton, RightChevronIcon, Tooltip, Text, Columns, Box } from "@myob/myob-widgets"
import { useNavigate } from "react-router-dom";

export default function Integrations() {
    let navigate = useNavigate(); 

    const redirectToIntegrationsPage = () =>{ 
        navigate("/integration");
    }

    const integrationTableData = {
        type: 'password',
        methodName: <Box><Tooltip triggerContent={<HomeIconIcon color="neutral" style={{ verticalAlign: "sub" }} />}>Password</Tooltip>&nbsp;&nbsp;<Text as="strong">Third-party integrations</Text></Box>,
        description: <Text tone='neutral' as='span'>Control which apps have access to your MYOB data</Text>,
        displayName: 'Password'
    };

    return <Card
            header={<Card.Header
                child={<PageHead title="Integrations" />}/>}
            body={<Card.Body child={
                <>
                    {
                        <>
                            {
                                <Columns alignY="center" data-testid="integrations" key={integrationTableData.type}>
                                    <Columns.Column span="3" ><Box >{integrationTableData.methodName}</Box></Columns.Column>
                                    <Columns.Column span="8" >
                                        <Box>{integrationTableData.description}</Box>
                                    </Columns.Column>
                                    <Columns.Column span="1" >
                                        <Box textAlign="right">
                                            <IconButton
                                                data-testid="integration-button"
                                                icon={<RightChevronIcon />}
                                                onClick={redirectToIntegrationsPage}/>
                                        </Box>
                                    </Columns.Column>
                                </Columns>
                            }
                        </>
                    }
                </>
            } />}
        />
}

